import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import Mention from "../../img/network.svg";

const Network = ({ isMobileOrTablet }) => {
  return (
    <section id="network" className="bg-section-company pt-5">
      <Container>
        <Row className="text-center" data-aos="fade-left">
          {isMobileOrTablet ? (
            <>
              <Col xs={12} md={6} className="text-center">
                <img
                  src={Mention}
                  className="img-fluid w-75"
                  alt="Innovation and interdisciplinary exchange with worldwide digitisation specialists"
                />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <Container className="px-2">
                  <p className="title-text py-3">Network</p>
                  <p className="Lead">
                    Together we focus on innovation and interdisciplinary
                    exchange with worldwide digitisation specialists. In doing
                    so, we make efficient use of the synergies to your
                    advantages. You benefit from a cross-sector network of
                    specialists. Thanks to this network, we can offer our
                    customers an almost unbeatable know-how.
                  </p>
                </Container>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className="text-end">
                <img
                  src={Mention}
                  className="img-fluid"
                  alt="Innovation and interdisciplinary exchange with worldwide digitisation specialists"
                />
              </Col>
              <Col xs={12} md={6} className="text-start">
                <h2 className="title-text py-3">Network</h2>
                <p className="lead">
                  Together we focus on innovation and interdisciplinary exchange
                  with worldwide digitisation specialists. In doing so, we make
                  efficient use of the synergies to your advantages. You benefit
                  from a cross-sector network of specialists. Thanks to this
                  network, we can offer our customers an almost unbeatable
                  know-how.
                </p>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Network;
