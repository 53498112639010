import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import Bi from "../../img/bi.svg";

const BizIntelligence = ({ isMobileOrTablet }) => {
  return (
    <section id="certification" className="bg-section-services pb-5">
      <Container id="BusinessIntelligence">
        <Row className="pt-5" data-aos="fade-left">
          {isMobileOrTablet ? (
            <>
              <Col xs={12} md={6} className="text-center">
                <img
                  src={Bi}
                  className="img-fluid w-50"
                  alt="Data profiling and delivery, discovery of relationships, patterns and principles, data transformation and data visualization"
                />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <h2 className="title-text pb-3">Business Intelligence</h2>
                <p className="lead px-3">
                  Our offer includes strategic and functional consulting, system
                  integration and customer-specific developments. This includes
                  data profiling and delivery, discovery of relationships,
                  patterns and principles, data transformation and data
                  visualization. We are happy to assist you in the efficient
                  realization of demanding software projects. We support you
                  either at your site or from our company headquarters.
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className="text-end">
                <img
                  src={Bi}
                  className="img-fluid"
                  alt="Data profiling and delivery, discovery of relationships, patterns and principles, data transformation and data visualization"
                />
              </Col>
              <Col xs={12} md={6} className="my-auto">
                <h2 className="title-text pb-3">Business Intelligence</h2>
                <p className="lead">
                  Our offer includes strategic and functional consulting, system
                  integration and customer-specific developments. This includes
                  data profiling and delivery, discovery of relationships,
                  patterns and principles, data transformation and data
                  visualization.
                </p>
                <p className="lead">
                  We are happy to assist you in the efficient realization of
                  demanding software projects. We support you either at your
                  site or from our company headquarters.
                </p>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default BizIntelligence;
