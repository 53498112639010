import React from "react";
import MapboxGl, { Marker } from "mapbox-gl";

import "./Map.css";

MapboxGl.accessToken =
  "pk.eyJ1IjoiYXNjbmV0LXdlYiIsImEiOiJjbDNmcG9peW8wdWZzM2NwdmpsbXE2bmhxIn0.Z9ArqskjcFBgrldH_84zWA";

class Mapp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 8.486078386599003,
      lat: 47.181589019512415,
      zoom: 11,
    };
  }

  componentDidMount() {
    const map = new MapboxGl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    // const popup = new MapboxGl.Popup({ offset: 25 }).setText(
    //   "ASCNET Solutions GmbH"
    // );

    const marker = new MapboxGl.Marker()
      .setLngLat([8.486078386599003, 47.181589019512415])
      //.setPopup(popup)
      .addTo(map);
  }

  render() {
    return (
      <div>
        <div
          ref={(el) => (this.mapContainer = el)}
          style={{ width: "100%", height: "35vh" }}
        />
      </div>
    );
  }
}

export default Mapp;
