import React, { useState, useEffect } from "react";
import MessagingIam from "./msxIam";
import Development from "./dev";
import BusinessIntelligence from "./bi";

import "./services.css";

const Services = () => {
  function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => {
        setMatches(media.matches);
      };
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [matches, query]);

    return matches;
  }

  //   // X-Small devices (portrait phones, less than 576px)
  // @media (max-width: 575.98px) { ... }

  // // Small devices (landscape phones, less than 768px)
  // @media (max-width: 767.98px) { ... }

  // // Medium devices (tablets, less than 992px)
  // @media (max-width: 991.98px) { ... }

  // // Large devices (desktops, less than 1200px)
  // @media (max-width: 1199.98px) { ... }

  // // X-Large devices (large desktops, less than 1400px)
  // @media (max-width: 1399.98px) { ... }

  const isMobileOrTablet = useMediaQuery("(max-width: 768px)");

  return (
    <div id="services">
      <Development isMobileOrTablet={isMobileOrTablet} />
      <MessagingIam isMobileOrTablet={isMobileOrTablet} />
      <BusinessIntelligence isMobileOrTablet={isMobileOrTablet} />
    </div>
  );
};

export default Services;
