import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import Developer from "../../img/dev.svg";

const Dev = ({ isMobileOrTablet }) => {
  return (
    <section id="development" className="bg-section-services pt-5">
      <Container id="development">
        <Row className="pt-2" data-aos="fade-up">
          {isMobileOrTablet ? (
            <>
              <Col xs={12} md={6} className="text-center">
                <img
                  src={Developer}
                  className="img-fluid w-50"
                  alt="Development of BI, IAM and Messaging solutions and sophisticated web based solutions"
                />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <h2 className="title-text pb-3">Development</h2>
                <p className="lead px-3">
                  We focus on the development of business-oriented BI, IAM and
                  Messaging solutions as well sophisticated web based individual
                  solutions. The offer ranges from conception and consulting to
                  implementation, technical workshops and individual user
                  training. We are happy to assist you in the efficient
                  realization of demanding software projects. We support you
                  either at your site or from our company headquarters.
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className="text-end">
                <img
                  src={Developer}
                  className="img-fluid"
                  alt="Development of BI, IAM and Messaging solutions and sophisticated web based solutions"
                />
              </Col>
              <Col xs={12} md={6} className="my-auto">
                <h2 className="title-text pb-3">Development</h2>
                <p className="lead">
                  We focus on the development of business-oriented BI, IAM and
                  Messaging solutions as well sophisticated web based individual
                  solutions. The offer ranges from conception and consulting to
                  implementation, technical workshops and individual user
                  training.
                </p>
                <p className="lead">
                  We are happy to assist you in the efficient realization of
                  demanding software projects. We support you either at your
                  site or from our company headquarters.
                </p>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Dev;
