import React from "react";
import FaqItem from "./faqitem";
import { Container, Col, Row } from "react-bootstrap";

import "./faq.css";

const faq = () => {
  return (
    <section id="faq" className="bg-section-faq px-3 py-5">
      <Container id="header" className="text-center" data-aos="fade-up">
        <Row className="row justify-content-md-center">
          <Col xs={12} xl={10} className="text-center">
            <h2 className="title-text text-center mb-4">
              Frequently Asked Questions
            </h2>
            <div className="accordion accordion-flush" id="questions">
              <FaqItem
                target={"question-one"}
                title={"Which Programming Technologies we use?"}
                content={
                  "In enterprise environments, we prefer the full scope of the .Net Framework and the simplicity of the Powershell. For web-based frontend applications, we fully rely on React JSX, Tailwind, Bootstrap, Framer-Motion and Syncfusion."
                }
              />
              <FaqItem
                target={"question-two"}
                title={"Which IAM-Messaging Platforms are our strengths?"}
                content={
                  "Our core skill are in the area of Microsoft Active-Directory, Microsoft Exchange and Microsoft Teams. In addition, the Azure or Microsoft 365 Cloud has also become part of our core competencies."
                }
              />
              <FaqItem
                target={"question-three"}
                title={"Which Business Intelligence Technologies we prefer?"}
                content={
                  "Our core skill are in mainly in the area of Microsoft SQL-Server and Microsoft Power BI, including BI software development, data modeling, data warehouse design, deployment and maintenance."
                }
              />
              <FaqItem
                target={"question-four"}
                title={"Where exactly are we on the road?"}
                content={
                  "Basically in the northwest of Switzerland. In any kind of remote work, we also accept mandates in the rest of Switzerland."
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default faq;
