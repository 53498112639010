import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import NavbarMenuItems from "./navbarMenu";

import "./navbar.css";

const NavigationBar = () => {
  return (
    <>
      <Navbar collapseOnSelect fixed="top" expand="sm" bg="dark" variant="dark">
        <Container fluid className="border border-dark" data-aos="zoom-in-up">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <NavbarMenuItems />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
