import React from "react";

import "./footer";

const Impressum = () => {
  return (
    <section id="impressum">
      <div className="container p-1">
        <p className="Lead">
          ASCNET Solutions GmbH<br></br> c/o uhuhus kmg<br></br> Chamerstrasse
          172
          <br></br>
          CH-6300 Zug<br></br> Tel.: +41 41 552 0632
        </p>
        <br></br>
        <p>
          Commercial register entry:<br></br>
          Registered company name: ASCNET Solutions GmbH<br></br> CH-ID:
          CH17040119220<br></br>
          Commercial Register Office of the Canton of Zug<br></br> VAT Number:
          CHE-180.731.045 MWST
        </p>
      </div>
      <hr></hr>
      <div className="container p-1">
        <h2 className="title-text-sm pb-2">
          Concept, web design and development
        </h2>
        <p>
          ASCNET Solutions GmbH c/o uhuhus kmg Chamerstrasse 172 CH-6300 Zug{" "}
        </p>
      </div>
      <div className="container p-1">
        <h2 className="title-text-sm pb-2">General Disclaimer</h2>
        <p>
          The author accepts no responsibility for the correctness, accuracy,
          topicality, reliability and completeness of the information.
        </p>
        <p>
          Liability claims against the author for material or immaterial damages
          resulting from access to or use or non-use of the published
          information, misuse of the connection or technical faults are
          excluded.
        </p>
        <p>
          All offers are non-binding. The author expressly reserves the right to
          change, supplement or delete parts of the pages or the entire offer
          without separate announcement or to discontinue the publication
          temporarily or permanently.
        </p>
      </div>
      <div className="container p-1">
        <h2 className="title-text-sm pb2">Disclaimer for Links</h2>
        <p>
          References and links to third party websites are outside our area of
          responsibility. We reject any responsibility for such websites. Access
          and use of such websites is at the user's own risk.
        </p>
      </div>
      <div className="container p-1">
        <h2 className="title-text-sm pb2">
          Terms and conditions on copyrights
        </h2>
        <p>
          The copyrights and all other rights to contents, pictures, photos or
          other files on the website belong exclusively to the company ASCNET
          Solutions GmbH or the specifically named holders of rights. For the
          reproduction of any elements, the written consent of the copyright
          holders must be obtained in advance.
        </p>
      </div>
      <div className="container p-1">
        <h2 className="title-text-sm pb2">Privacy Policy</h2>
        <p>
          Based on Article 13 of the Swiss Federal Constitution and the data
          protection provisions of the Swiss Confederation (Data Protection Act,
          DSG), every person is entitled to protection of his or her privacy and
          protection against misuse of his or her personal data. We comply with
          these provisions. Personal data is treated in strict confidence and is
          neither sold nor passed on to third parties.
        </p>
        <p>
          In close cooperation with our hosting provider, we make every effort
          to protect the databases as well as possible against unauthorised
          access, loss, misuse or falsification.
        </p>
        <p>
          When our websites are accessed, the following data is stored in log
          files: IP address, date, time, ISP, City, Continent, Country, Origin
          and Region. This usage data forms the basis for statistical, anonymous
          evaluations, so that trends can be identified, on the basis of which
          we can improve our offers accordingly.
        </p>
      </div>
    </section>
  );
};

export default Impressum;
