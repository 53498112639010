import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import Mountain from "../../img/swissness.svg";

const Swissness = ({ isMobileOrTablet }) => {
  return (
    <section id="swissness" className="bg-section-company pb-5">
      <Container>
        <Row className="pt-5" data-aos="fade-right">
          {isMobileOrTablet ? (
            <>
              <Col xs={12} md={6} className="text-center border-dark">
                <img
                  src={Mountain}
                  className="img-fluid w-75"
                  alt="We rely on Switzerland as a production location"
                />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <Container className="px-2">
                  <p className="title-text py-3">Swissness</p>
                  <p className="lead">
                    Precision, stability and reliability are not only attributes
                    of Swissness for us, but also meet the requirements of our
                    work. We rely on Switzerland as a production location
                    because we consider customer proximity to be an important
                    success factor.
                  </p>
                </Container>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className="text-end">
                <h2 className="title-text">Swissness</h2>
                <p className="lead">
                  Precision, stability and reliability are not only attributes
                  of Swissness for us, but also meet the requirements of our
                  work. We rely on Switzerland as a production location because
                  we consider customer proximity to be an important success
                  factor.
                </p>
              </Col>
              <Col xs={12} md={6}>
                <img
                  src={Mountain}
                  className="img-fluid"
                  alt="We rely on Switzerland as a production location"
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Swissness;
