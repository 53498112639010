import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import "./about.css";
import Logo from "../../img/Logo.png";
import Mission from "../../img/mission.svg";
import Vision from "../../img/vision.svg";
import Technology from "../../img/technology.svg";

const about = () => {
  return (
    <section id="about" className="bg-section-about text-dark p-5">
      <Container>
        <div data-aos="fade-right">
          <img
            src={Logo}
            alt="Innovative Solutions for your Business"
            className="img-fluid center d-none d-md-block"
          />
        </div>
        <Row className="pt-1 text-center" data-aos="fade-left">
          <Col xs={12} md={4} className="pt-5">
            <img src={Mission} alt="Our mission" className="img-fluid center" />
            <h3 className="title-text-sm pt-3">Our Mission</h3>
            <p className="Lead px-2">
              We create sustainable and competitive advantages for ambitious
              customers who want to shape their own future.
            </p>
          </Col>
          <Col xs={12} md={4} className="pt-5">
            <img src={Vision} alt="Our vision" className="img-fluid center" />
            <h3 className="title-text-sm pt-3">Our Vision</h3>
            <p className="Lead px-2">
              A flat structure and short communication channels facilitate
              cooperation and accelerate decision-making processes.
            </p>
          </Col>
          <Col xs={12} md={4} className="pt-5">
            <img
              src={Technology}
              alt="Our technology"
              className="img-fluid center"
            />
            <h3 className="title-text-sm pt-3">Our Technology</h3>
            <p className="Lead px-2">
              Our compact structure enables us to react quickly and easily to
              changes in the market, technologies or individual projects.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default about;
