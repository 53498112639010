import React, { useState } from "react";
import { Modal, Button, Form, Container, Col, Row } from "react-bootstrap";
import Mapp from "./Map";

import "./contact.css";

const Contact = ({
  companyName,
  companyAddress,
  companyLocation,
  companyCountry,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const submitEMail = (e) => {
    e.preventDefault();

    const formErrors = validateFormErrors();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    }
    console.log({ form });
  };

  const setField = (field, value) => {
    setForm({ ...form, [field]: value });

    if (!!errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  const validateFormErrors = () => {
    const { mail, message } = form;
    const newErrors = {};

    if (!mail || mail === "") {
      newErrors.mail = "Enter an EMail Address!";
    }

    if (!message || message === "") {
      newErrors.message = "Enter your Inquiry!";
    } else if (message.length < 20) {
      newErrors.message = "Your Inquiry must contain at least 20 characters!";
    }

    return newErrors;
  };

  return (
    <>
      <section id="contact" className="bg-dark text-light p-5">
        <Container>
          <Row className="pt-2">
            <Col
              xs={12}
              md={4}
              className="pt-1 text-center"
              data-aos="fade-down"
            >
              <h2 className="title-text-lg pb-3">Contact Info</h2>
              <ul className="list-group list-group-flush lead">
                <li className="list-group-item bg-dark text-light">
                  <span className="fw-bold">{companyName}</span>
                </li>
                <li className="list-group-item bg-dark text-light">
                  <span className="fw-bold">
                    {companyAddress.split(";")[0]}
                  </span>
                </li>
                <li className="list-group-item bg-dark text-light">
                  <span className="fw-bold">
                    {companyAddress.split(";")[1]}
                  </span>
                </li>
                <li className="list-group-item bg-dark text-light">
                  <span className="fw-bold">
                    {companyLocation} - {companyCountry}
                  </span>
                </li>
                <li className="list-group-item bg-dark text-light">
                  <span className="fw-bold"></span>
                </li>
                <li className="list-group-item bg-dark text-light pb-3">
                  <button
                    className="btn btn-secondary"
                    variant="link"
                    // style={{
                    //   backgroundColor: "#212529",
                    //   color: "white",
                    //   border: "0",
                    // }}
                    onClick={handleShow}
                  >
                    Click here to contact us
                  </button>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={8} data-aos="fade-up">
              <Mapp />
            </Col>
          </Row>

          {/* <div className="row g-4 border border-warning">
            <div className=" border border-warning"></div>
            <div className="col-md border border-warning"></div>
          </div> */}
        </Container>
      </section>

      {/* Modal Dialog for the Contect form and to send an EMail */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal text-white"
        show={show}
        onHide={handleClose}
        data-aos="cust-zoom-in-left"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {/* <i className="bi bi-envelope" style={{ paddingRight: "1rem" }}></i> */}
            <h3 className="title-text-sm">Contact Us...</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name or Company</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Name or Company"
                onChange={(e) => setField("name", e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email Address*</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter your Email Address"
                value={form.mail}
                onChange={(e) => setField("mail", e.target.value)}
                isInvalid={!!errors.mail}
              />
              <Form.Control.Feedback type="invalid" style={{ color: "pink" }}>
                {errors.mail}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="message">
              <Form.Label>Your Inquiry*</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter your Inquiry"
                rows={10}
                value={form.message}
                onChange={(e) => setField("message", e.target.value)}
                isInvalid={!!errors.message}
              />
              <Form.Control.Feedback type="invalid" style={{ color: "pink" }}>
                {errors.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            // disabled={!form.email}
            variant="secondary"
            onClick={submitEMail}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Contact;
