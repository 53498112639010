import React from "react";
//import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from "./components/navbar/navbar";
import Header from "./components/header/header";
import Services from "./components/services/services";
import AboutUs from "./components/about/about";
import Company from "./components/company/company";
import Contact from "./components/contact/contact";
import Faq from "./components/faq/faq";
import Footer from "./components/footer/footer";

// import "./animations.css";
import "./aoscustom.css";
import "./App.css";

const companyName = "ASCNET Solutions GmbH";
const companyAddress = "c/o uhuhus kmg;Chamerstrasse 172";
const companyLocation = "6300 Zug";
const companyCountry = "Switzerland";

const App = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Company />
      <Services />
      <Faq />
      <AboutUs />
      <Contact
        companyName={companyName}
        companyAddress={companyAddress}
        companyLocation={companyLocation}
        companyCountry={companyCountry}
      />
      <Footer companyName={companyName} />
    </>
  );
};

export default App;
