import React from "react";
import { Nav } from "react-bootstrap";
//import { Link } from "react-router-dom";

const navbarMenu = () => {
  const menuLinks = [
    {
      id: "#company",
      menuName: "Company",
    },
    {
      id: "#services",
      menuName: "Services",
    },
    {
      id: "#faq",
      menuName: "FAQ",
    },
    {
      id: "#about",
      menuName: "About Us",
    },
    {
      id: "#contact",
      menuName: "Contact",
    },
  ];

  return menuLinks.map(({ id, menuName }) => (
    <Nav.Link key={id} href={id}>
      <h3>{menuName}</h3>
    </Nav.Link>
  ));
};

export default navbarMenu;
