import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import "./header.css";
import Logo from "../../img/Logo_smart_xl.png";

const Header = () => {
  return (
    <section className="bg-dark text-light pt-lg-5">
      {/* // xs — screen width < 576px
      // sm — screen width >= 576px
      // md — screen width >= 768px
      // lg — screen width >= 992px
      // xl — screen width >= 1200px
      // xxl — screen width >= 1400px */}

      <Container id="header" className="pt-4">
        <Row className="text-center" data-aos="cust-zoom-in-left">
          <Col xs={12} className="pb-5">
            <div>
              <img
                src={Logo}
                alt="ASCNET - Smart Solutions for your Business"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
        <Row className="text-center" data-aos="cust-zoom-in-right">
          <Col xs={12} className="pb-5 d-none d-md-block">
            <div data-aos="cust-bounce">
              <i className="bi bi-arrow-down-circle arrowdown-icon"></i>
              <p>Get Started</p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <div className="container pb-5" data-aos="cust-zoom-in-left">
        <div>
          <img src={Logo} alt="Logo" className="img-fluid" />
        </div>
        <div
          className="container center d-none d-md-block"
          data-aos="cust-bounce"
        >
          <i className="bi bi-arrow-down-circle arrowdown-icon"></i>
          <p>Get Started</p>
        </div>
      </div> */}
    </section>
  );
};

export default Header;
