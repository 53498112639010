import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import IamMsx from "../../img/iammsx.svg";

const MsxIam = ({ isMobileOrTablet }) => {
  return (
    <section id="backpack" className="bg-section-services">
      <Container id="MessagingIam">
        <Row className="pt-5" data-aos="fade-right">
          {isMobileOrTablet ? (
            <>
              <Col xs={12} md={6} className="text-center">
                <img
                  src={IamMsx}
                  className="img-fluid w-50"
                  alt="Planning, development, implementation and operation of simple and complex IAM and Messaging solutions"
                />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <h2 className="title-text pb-3">IAM - Messaging</h2>
                <p className="lead px-3">
                  IAM and Messaging are part of a modern business life. For
                  companies throughout the economy, we offer solutions for
                  efficient operation. We support you in planning, development,
                  implementation and the operation of simple and complex IAM and
                  Messaging solutions. Our offer includes strategic and
                  functional consulting, system integration as well as
                  customer-specific developments and operational support.
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className="my-auto text-end">
                <h2 className="title-text pb-3">IAM - Messaging</h2>
                <p className="lead">
                  IAM and Messaging are part of a modern business life. For
                  companies throughout the economy, we offer solutions for
                  efficient operation.
                </p>
                <p className="lead">
                  We support you in planning, development, implementation and
                  the operation of simple and complex IAM and Messaging
                  solutions.
                </p>
                <p className="lead">
                  Our offer includes strategic and functional consulting, system
                  integration as well as customer-specific developments and
                  operational support.
                </p>
              </Col>
              <Col xs={12} md={6} className="text-start">
                <img
                  src={IamMsx}
                  className="img-fluid"
                  alt="Planning, development, implementation and operation of simple and complex IAM and Messaging solutions"
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default MsxIam;
