import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import Cert from "../../img/certification.svg";

const Certification = ({ isMobileOrTablet }) => {
  return (
    <section id="certification" className="bg-section-company">
      <Container>
        <Row className="pt-5" data-aos="fade-left">
          {isMobileOrTablet ? (
            <>
              <Col xs={12} md={6} className="text-center">
                <img
                  src={Cert}
                  className="img-fluid w-50"
                  alt="We have been at the forefront of certification since 1998"
                />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <Container className="px-2">
                  <p className="text-center title-text py-3">Certifications</p>
                  <p className="lead">
                    In order to objectively assess IT competencies, Microsoft
                    created its own certification program decades ago, which is
                    recognized worldwide. The certifications are subject to
                    constant development. We have been at the forefront of
                    certification since 1998. This way, our concentrated
                    expertise can always be used as proof of performance.
                  </p>
                </Container>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className="text-end">
                <img
                  src={Cert}
                  className="img-fluid"
                  alt="We have been at the forefront of certification since 1998"
                />
              </Col>
              <Col xs={12} md={6} className="text-start">
                <h2 className="title-text py-3">Certifications</h2>
                <p className="lead">
                  In order to objectively assess IT competencies, Microsoft
                  created its own certification program decades ago, which is
                  recognized worldwide. The certifications are subject to
                  constant development. We have been at the forefront of
                  certification since 1998. This way, our concentrated expertise
                  can always be used as proof of performance.
                </p>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Certification;
