import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import Briefcase from "../../img/backbag.svg";

const Backpack = ({ isMobileOrTablet }) => {
  return (
    <section id="backpack" className="bg-section-company">
      <Container>
        <Row className="pt-5" data-aos="fade-right">
          {isMobileOrTablet ? (
            <>
              <Col xs={12} md={6} className="text-center">
                <img
                  src={Briefcase}
                  className="img-fluid w-75"
                  alt="We can prove a well-filled experience backpack"
                />
              </Col>
              <Col xs={12} md={6} className="text-center">
                <Container className="px-2">
                  <p className="title-text py-3">Backpack</p>
                  <p className="Lead">
                    With more than 20 years of know-how from the IT front we can
                    prove a well-filled experience backpack. In it we carry many
                    useful things that give us orientation, so that we can
                    quickly find our way around and make decisions. "Pyramids
                    and silo thinking" are foreign to us and have shown that
                    agility is lost in business. Our strategy is fully oriented
                    towards the customer, so that the greatest possible
                    maximisation of customer benefit is achieved.
                  </p>
                </Container>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className="text-end">
                <h2 className="title-text">Backpack</h2>
                <p className="lead">
                  With more than 20 years of know-how from the IT front we can
                  prove a well-filled experience backpack. In it we carry many
                  useful things that give us orientation, so that we can quickly
                  find our way around and make decisions. "Pyramids and silo
                  thinking" are foreign to us and have shown that agility is
                  lost in business. Our strategy is fully oriented towards the
                  customer, so that the greatest possible maximisation of
                  customer benefit is achieved.
                </p>
              </Col>
              <Col xs={12} md={6} className="text-start">
                <img
                  src={Briefcase}
                  className="img-fluid"
                  alt="We can prove a well-filled experience backpack"
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Backpack;
