import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import Impressum from "./impressum";

import "./footer.css";

const Footer = ({ companyName }) => {
  const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <footer className="p-5 bg-dark text-white text-center position-relative">
        <Container>
          <p className="lead">
            © {new Date().getFullYear()} {companyName}
          </p>
          <p className="h6">
            <button
              variant="link"
              style={{
                backgroundColor: "#212529",
                color: "white",
                border: "0",
              }}
              onClick={() => setLgShow(true)}
            >
              Impressum + Privacy Policy
            </button>
          </p>
          <a href="#header" className="position-absolute bottom-0 end-0 p-2">
            <i className="bi bi-arrow-up-circle arrowup-icon"></i>
          </a>
        </Container>
      </footer>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        scrollable={true}
        className="custom-modal text-white"
        show={lgShow}
        onHide={() => setLgShow(false)}
        data-aos="cust-zoom-in-left"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            <h2 className="title-text-lg">Impressum + Privacy Policy</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Impressum />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Footer;
